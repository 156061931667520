import React, { useState } from "react";
import toast from "react-hot-toast";

import axios from "axios";

import { Button, Checkbox, Form, FormModal, Select } from "../UI/components";

export default function ExportTemplateFieldsEditor({
  exportTemplate,
  exportTemplateField = null,
  typesOptions,
  originalColumnOptions,
  isOpen,
  closeModal,
  onUpdate,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [originalColumn, setOriginalColumn] = useState(
    exportTemplateField === null ? 0 : exportTemplateField.column_title,
  );
  const [columnTitle, setColumnTitle] = useState("Title");
  const [translatable, setTranslatable] = useState(
    exportTemplateField === null ? true : exportTemplateField.translatable,
  );
  const [type, setType] = useState(exportTemplateField === null ? 0 : exportTemplateField.type);
  const [stripHtml, setStripHtml] = useState(
    exportTemplateField === null ? false : exportTemplateField.strip_html,
  );
  const [characterLimit, setCharacterLimit] = useState(
    exportTemplateField === null ? 0 : exportTemplateField.character_limit,
  );
  const [uppercase, setUppercase] = useState(
    exportTemplateField === null ? false : exportTemplateField.uppercase,
  );

  const onCancel = () => {
    closeModal(null);
  };

  const setFieldConstants = (selected) => {
    setOriginalColumn(selected.target.value);
    switch (selected.target.value) {
      case "0":
      case "2":
        setType(0);
        setTranslatable(true);
        break;
      case "1":
      case "3":
      case "4":
      case "5":
      case "6":
      case "8":
        setType(0);
        setTranslatable(false);
        break;
      case "7":
      case "9":
      case "10":
      case "11":
      case "12":
        setType(1);
        setTranslatable(false);
        break;
      case "13":
      case "14":
      case "15":
      case "16":
      case "17":
        setType(2);
        setTranslatable(false);
        break;
      case "18":
        setType(2);
        setTranslatable(false);
        break;
      case "19":
        setType(2);
        setTranslatable(false);
        break;
      case "20":
      case "21":
        setType(0);
        setTranslatable(false);
        break;
      case "22":
        setType(2);
        setTranslatable(false);
        break;
      case "23":
        setType(0);
        setTranslatable(false);
        break;
    }

    setColumnTitle(selected.target.options[selected.target.selectedIndex].text);
  };

  const onSubmit = async () => {
    const formData = new FormData();

    setIsLoading(true);

    formData.append("export_template_field[original_column]", originalColumn);
    formData.append("export_template_field[column_title]", columnTitle);
    formData.append("export_template_field[strip_html]", stripHtml);
    formData.append("export_template_field[translatable]", translatable);
    formData.append("export_template_field[uppercase]", uppercase);
    formData.append("export_template_field[type]", type);
    formData.append("export_template_field[character_limit]", characterLimit);
    formData.append("export_template_field[export_template_id]", exportTemplate.id);

    if (exportTemplateField) {
      try {
        const result = await axios({
          method: "patch",
          url: `/export_template_fields/${exportTemplateField.id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });
        console.log("RESULT", result);
        toast.success("Export Template Field updated");
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error updating the Export Template Field");
      }
    } else {
      try {
        const result = await axios({
          method: "post",
          url: `/export_template_fields`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });
        console.log("RESULT", result);

        toast.success("Export Template Field created");
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error creating the Export Template Field.");
      }
    }

    setIsLoading(false);
    await onUpdate();
    onCancel();
  };

  return (
    <>
      <FormModal title="Export Template Field" {...{ isOpen, onCancel }}>
        <Form onSubmit={onSubmit} defaultValues={exportTemplateField}>
          <div className="py-5">
            <div className="space-y-3">
              <div className="form-group">
                <Select
                  label="Original Column"
                  name="original_column"
                  options={originalColumnOptions}
                  onChange={(selected) => setFieldConstants(selected)}
                  required
                />
              </div>
            </div>
            <div className="space-y-3">
              <div className="form-group">
                <label>Column Title</label>
                <input
                  type="text"
                  name="column_title"
                  value={columnTitle}
                  onChange={(value) => setColumnTitle(value.target.value)}
                />
              </div>
            </div>
            {translatable && (
              <>
                <div className="relative flex items-center py-5">
                  <div className="border-400 flex-grow border-t"></div>
                  <span className="mx-4 flex-shrink text-gray-400">Translation Options</span>
                  <div className="border-400 flex-grow border-t"></div>
                </div>
                <div className="hidden space-y-3">
                  <div className="form-group">
                    <label>Type</label>
                    <select name="type" value={type} disabled={true} required>
                      {typesOptions.map((type) => {
                        return (
                          <option value={type["value"]} key={type["label"]}>
                            {type["label"]}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="space-y-3">
                  <div className="form-group">
                    <div className="grid grid-cols-2 justify-items-center gap-4">
                      <Checkbox
                        label="Strip HTML"
                        name="strip_html"
                        value={stripHtml}
                        disabled={!translatable}
                        onChange={(value) => setStripHtml(value.target.checked)}
                      />

                      <Checkbox
                        label="Uppercase"
                        name="uppercase"
                        value={uppercase}
                        disabled={!translatable}
                        onChange={(value) => setUppercase(value.target.checked)}
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-3">
                  <div className="form-group">
                    <div className="flex items-center justify-between">
                      <label>Character Limit</label>
                      <span className="text-xs text-gray-500">Leave 0 for no limit</span>
                    </div>
                    <input
                      type="text"
                      name="character_limit"
                      defaultValue={characterLimit}
                      onChange={(value) => setCharacterLimit(value.target.value)}
                      disabled={!translatable}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="btn-group text-right">
            <button type="button" className="btn btn-neutral" onClick={onCancel}>
              Cancel
            </button>
            <Button type="submit" label="Save" className="btn" showLoading={isLoading} />
          </div>
        </Form>
      </FormModal>
    </>
  );
}
